

















































































import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class UserAuth extends Vue {
  public form_list: MyForm.formList[] = [];
  public id_card_1 = ""; // 身份证正面照片地址
  public id_card_2 = ""; // 身份证反面照片地址
  public frontv = ""; // 身份证正面识别的key值
  public back = ""; // 身份证反面识别的key值
  public disabled = false; // 是否能编辑（是否已认证）

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 设置form内容
  setFormData(name, value, type = "value") {
    this.form_list.forEach((item, index) => {
      if (name === item.name) {
        this.$set(this.form_list[index], type, value);
      }
    });
  }
  // 上传身份证
  uploadIdCard(file, detail) {
    this.$api.uploadFile(file.file, res => {
      if (detail.name === "id_card_1") {
        this.id_card_1 = res.data.url;
        this.ocrIdcard(res.data.url, "frontv");
      } else if (detail.name === "id_card_2") {
        this.id_card_2 = res.data.url;
        this.ocrIdcard(res.data.url, "back");
      }
    });
  }
  // 识别身份证正面，获取姓名和号码
  ocrIdcard(img, name) {
    let _this = this;
    this.$api.request({
      url: "user/member/ocridcard",
      data: { img },
      success(res) {
        if (name === "frontv") {
          _this.frontv = res.data.key;
        } else if (name === "back") {
          _this.back = res.data.key;
        }
        if (res.data.name) {
          _this.setFormData("name", res.data.name);
        }
        if (res.data.id_card_number) {
          _this.setFormData("id_card", res.data.id_card_number);
        }
      }
    });
  }
  // 提交
  submit() {
    let _this = this;
    this.$api.request({
      url: "user/member/idcaer-authentication",
      data: {
        frontv: this.frontv,
        back: this.back
      },
      success(res) {
        _this.$api.goBack();
      }
    });
  }
  // 获取用户信息
  getMember() {
    let _this = this;
    this.$api.request({
      url: "user/member/info",
      success(res) {
        let card = res.data.card;
        if (card && card.card_id) {
          _this.setFormData("name", card.name || "");
          _this.setFormData("id_card", card.id_card_number || "");
          _this.id_card_1 = card.card_front_img;
          _this.id_card_2 = card.card_reverse_img;
          _this.disabled = true;
        }
      }
    });
  }

  init() {
    this.id_card_1 = "";
    this.id_card_2 = "";
    this.frontv = "";
    this.back = "";
    this.disabled = false;
    this.form_list = [
      {
        name: "name",
        value: "",
        label: "真实姓名",
        type: "text",
        placeholder: "请上传证件材料"
      },
      {
        name: "id_card",
        value: "",
        label: "身份证号",
        type: "text",
        placeholder: "请上传证件材料"
      }
    ];
    this.$api.refreshForm([this.$refs.form]);

    this.getMember();
  }
}
